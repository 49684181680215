<template>
  <v-container fill-height fluid class="pagenotfound--container">
    <v-row align="center" justify="center">
      <v-col align="center">
        <img
          :src="notFoundImage"
          alt="image not found"
          class="pagenotfound--img"
        />
        <p class="ma-0 text--bold--20 pagenotfound--title--text">
          ขออภัย ไม่พบหน้าที่ต้องการ
        </p>
        <p class="ma-0 text--regular--16">
          เราพบบางสิ่งผิดพลาด คุณสามารถค้นหาใหม่อีกครั้ง โดยเริ่มต้นในหน้าแรก
        </p>
        <v-container
          class="text-center col-md-12 col-12 col-sm-12 col-12 pagenotfound--btn--container"
        >
          <v-btn
            elevation="0"
            medium
            rounded
            color="primary"
            @click="$router.push('/')"
            class="pagenotfound--btn"
          >
            <span class="black--text text--regular--14"
              >กลับสู่หน้าแรก</span
            ></v-btn
          >
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    notFoundImage: require("../assets/notfound.svg")
  })
};
</script>

<style scoped>
@media (max-width: 480px) {
  .pagenotfound--img {
    width: 284px;
    height: 214px;
  }

  .pagenotfound--btn {
    padding: 12px 78px 12px 78px !important;
    height: 45px !important;
  }

  .pagenotfound--btn--container {
    margin-top: 50px;
    height: 54px;
  }

  .pagenotfound--title--text {
    margin-top: 50px !important;
  }

  .pagenotfound--container {
    padding-top: 31px;
    padding-bottom: 132px;
  }
}

@media (min-width: 481px) {
  .pagenotfound--img {
    width: 376px;
    height: 284px;
  }
  .pagenotfound--btn {
    padding: 12px 78px 12px 78px !important;
    height: 45px !important;
  }

  .pagenotfound--btn--container {
    margin-top: 47px;
  }

  .pagenotfound--title--text {
    margin-top: 46px !important;
  }

  .pagenotfound--container {
    padding-top: 84px;
    padding-bottom: 116px;
  }
}
</style>
